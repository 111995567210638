import {Injectable} from '@angular/core';
import {doc, docData, Firestore, updateDoc} from '@angular/fire/firestore';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationSkipped,
  Params,
  Router
} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';
import {uniq} from 'lodash';
import {filter, switchMap, take} from 'rxjs';

import {ROUTES_PATH} from '../models/routes-path.enum';

const waitTillNavigationEnds = () =>
  filter((e: RouterEvent) =>
    [NavigationEnd, NavigationCancel, NavigationSkipped].some(type => e instanceof type)
  );

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    private router: Router,
    private firestore: Firestore,
    private sessionService: SessionService
  ) {}

  navigateToModule(module?: string, queryParams?: Params) {
    const segments = ['t', this.sessionService.teamIndex()];

    const authModules = [ROUTES_PATH.SIGN_IN, ROUTES_PATH.SIGN_UP_SUCCESS];
    const isAuthModule = authModules.includes(module as ROUTES_PATH);

    if (module && !isAuthModule) {
      segments.push(module);
    }

    const urlTree = this.router.createUrlTree(segments, {
      queryParams
    });

    return this.router.navigateByUrl(urlTree);
  }

  navigateToSpace(index: number, showLoader = true) {
    const url = this.router.url.replace(String(this.sessionService.teamIndex()), String(index));

    if (showLoader) {
      this.showLoaderOnNavigation();
    }

    this.updateLastVisitedSpaces();

    return this.router.navigateByUrl(url);
  }

  private showLoaderOnNavigation() {
    this.sessionService.setLoading(true);

    this.router.events
      .pipe(waitTillNavigationEnds(), take(1))
      .subscribe(() => this.sessionService.setLoading(false));
  }

  private updateLastVisitedSpaces() {
    const ref = doc(this.firestore, `users/${this.sessionService.userId()}`);

    this.router.events
      .pipe(
        waitTillNavigationEnds(),
        take(1),
        switchMap(() => docData(ref)),
        filter(Boolean),
        take(1)
      )
      .subscribe((data: any) => {
        const spaces = data.lastVisitedTeams || [];

        spaces.unshift(this.sessionService.teamId());

        updateDoc(ref, {lastVisitedTeams: uniq(spaces).slice(0, 10)});
      });
  }
}
